<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Blog</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Blog
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('blog', 'create')"
                         @click="createBlog()"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Blog
                  </v-btn>
                  <v-btn
                      v-if="checkIsAccessible('category', 'create')"
                      @click="blogCategory()"
                      class="mt-1 ml-3 btn btn-standard"
                  >
                    Blog Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Name"
                      v-model="search.name"
                      v-on:keyup.enter="searchBlog"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                      :items="categories"
                      v-model="search.category_id"
                      v-on:keyup.enter="searchBlog"
                      @input="search.category_id = $event !== null ? $event : ''"
                      label="Blog Category"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.publish_date"
                          v-on:keyup.enter="searchBlog"
                          @input="search.publish_date = $event !== null ? $event : ''"
                          label="Publish Date"
                          clearable
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.publish_date"
                        outlined
                        dense
                        no-title
                        @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchBlog"
                      @input="search.is_active = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="value"
                      :items="status"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                      @click.prevent="searchBlog"
                      class="mt-1 btn btn-primary"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')"><strong>Name</strong></th>
                  <th><strong>Category</strong></th>
                  <th><strong>Publish Date</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="blog in sortedItems" :key="blog.id">
                    <td>
                      <a @click="editBlog(blog.id)"
                         class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ blog.name }}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ blog.category ? blog.category.name : '--' }}
                    </td>
                    <td>
                      {{ blog.formatted_publish_date }}
                    </td>
                    <td>
                      <span class="badge badge-success text-lg`"
                            v-bind:class="{ 'badge-success': blog.is_active, 'badge-danger': !blog.is_active }"
                      >{{ blog.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('category', 'edit')">
                              <a @click="editBlog(blog.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('category', 'delete')">
                              <a class="navi-link" @click.prevent="deleteBlog(blog.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="5" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllBlogs"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BlogService from "@/services/cms/blog/BlogService";
import BlogCategoryService from "@/services/cms/blog/category/BlogCategoryService";

const blog = new BlogService();
const blogCategory = new BlogCategoryService();

export default {
  data() {
    return {
      menu: false,
      loading: false,
      blogs: [],
      categories: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search: {
        name: '',
        category_id: '',
        publish_date: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.getAllBlogs();
    this.getAllCategories();
  },
  computed: {
    sortedItems() {
      const list = this.blogs.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    blogCategory() {
      this.$router.push({
        name: "category",
      });
    },
    createBlog() {
      this.$router.push({
        name: "blog-create"
      });
    },
    editBlog(blogId) {
      this.$router.push({
        name: 'blog-edit',
        params: {blogId: blogId}
      });
    },
    getAllBlogs() {
      this.loading = true;
      blog
          .paginate(this.search, this.page)
          .then(response => {
            this.blogs = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllCategories() {
      blogCategory
          .getParent()
          .then(response => {
            this.categories = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchBlog() {
      this.getAllBlogs();
    },
    deleteBlog(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            blog
                .delete(id)
                .then((response) => {
                  this.getAllBlogs()
                  this.$snotify.success("Blog Category Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>